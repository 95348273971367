import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import useTranslation from '../../hooks/useTranslation';
import { LocalesUnion } from '../../types/interfaces';

const drawerWidth = 200;

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

const DrawerMenu = ({
  onClose,
  open,
  locale,
}: {
  onClose: () => void;
  open: boolean;
  locale: LocalesUnion;
}) => {
  const handleClose = () => {
    onClose();
  };

  const { t } = useTranslation(locale);

  return (
    <Drawer anchor="right" open={open}>
      <ClickAwayListener onClickAway={handleClose}>
        <div style={{ width: drawerWidth, padding: 10 }}>
          <List>
            <ListItemLink href="/" button>
              <span>{t('NAVBAR_HOME')}</span>
            </ListItemLink>
            <ListItemLink href="mailto:info@deevent.app" button>
              <span>{t('NAVBAR_CONTACT')}</span>
            </ListItemLink>
          </List>
        </div>
      </ClickAwayListener>
    </Drawer>
  );
};

DrawerMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default DrawerMenu;
