export const NICOLAS_URL =
  'https://res.cloudinary.com/deevent-app/image/upload/v1601822874/static/nicolas_wklzbx.jpg';
export const SCREENSHOT_URL =
  'https://res.cloudinary.com/deevent-app/image/upload/v1601822883/static/screenshot_lko9ow.png';
export const PLAY_STORE_URL =
  'https://res.cloudinary.com/deevent-app/image/upload/v1601822863/static/google-play-badge_yfez7e.png';
export const APPLE_STORE_URL =
  'https://res.cloudinary.com/deevent-app/image/upload/v1601822863/static/apple-store_yjqkjw.svg';

export const SITE_BASE_URL = 'https://deevent.app/';
export const SITE_NAME = 'Deevent';
