import React, { useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import '../styles/header.scss';
import Logo from './Logo/Logo';
import DrawerMenu from './DrawerMenu/DrawerMenu';
import Icon from './Icon/Icon';
import { LocalesUnion } from '../types/interfaces';
import useTranslation from '../hooks/useTranslation';
import { BREAKPOINTS, PRIMARY_COLOR } from '../core/constants';

interface Props {
  locale: LocalesUnion;
  collapsed: boolean;
}

const NavBar = styled.ul`
  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuIcon = styled.a`
  color: white;

  @media (min-width: 769px) {
    display: none;
  }
`;

const NavItem = styled.li`
  width: 70px;
  // justify-content: center;

  &:hover {
    color: red;
  }

  &:after {
    position: absolute;
    content: '';
    width: 70px;
    height: 2px;
    border-radius: 10px;
    bottom: 1;
    left: 1;
    right: 1;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.25s cubic-bezier(1, 0.25, 0, 0.75) 0s;
    transition: all 0.25s cubic-bezier(1, 0.25, 0, 0.75) 0s;
  }

  &:hover:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
`;

const Container = styled.div`
  position: fixed;

  @media ${BREAKPOINTS.sm} {
    position: static;
    background: ${PRIMARY_COLOR};
    margin-bottom: -20px;
    z-index: 9000;
  }
`;

const Header: React.FC<Props> = ({ collapsed, locale = 'en' }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const linkClasses = classnames('nav-link transition', {
    'text-light': !collapsed,
  });

  const logoClasses = classnames('navbar-brand transition', {
    'text-light': !collapsed,
  });

  const buttonClass = classnames('active transition nav-link px-3', {
    'bg-white': !collapsed,
    'bg-primary': collapsed,
    'text-light': collapsed,
    'text-primary': !collapsed,
  });

  const containerClass = classnames(
    'navbar transition d-flex nav-pills sticky-top w-100',
    { 'scrolled shadow': collapsed, 'unscrolled pt-4': !collapsed }
  );

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const { t } = useTranslation(locale);

  return (
    <>
      <Container className={containerClass}>
        <div className="d-flex align-items-center flex-1 w-100 justify-content-between px-5">
          <a className={logoClasses} href={`/${locale}`}>
            <Logo light={!collapsed} />
            {collapsed && 'Deevent'}
          </a>
          <NavBar className="navbar-nav flex-row">
            <NavItem className="nav-item ml-4">
              <a
                style={{ textAlign: 'center' }}
                className={linkClasses}
                href={`/${locale}`}
              >
                {t('NAVBAR_HOME')}
              </a>
            </NavItem>
            <li className="nav-item ml-4" style={{ width: 100 }}>
              <a
                style={{ textAlign: 'center' }}
                href="mailto:info@deevent.app"
                className={buttonClass}
              >
                {t('NAVBAR_CONTACT')}
              </a>
            </li>
          </NavBar>
          <MenuIcon onClick={handleOpenDrawer} className="text-light">
            <Icon light={!collapsed} path="/menu.svg" />
          </MenuIcon>
          <DrawerMenu
            locale={locale}
            onClose={handleCloseDrawer}
            open={drawerOpen}
          />
        </div>
      </Container>
    </>
  );
};

export default Header;
