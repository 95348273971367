const DEVICES = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '576px',
  tablet: '767px',
  laptop: '992px',
  laptopL: '1200px',
  desktop: '1400px',
};

export const BREAKPOINTS = {
  xxs: `(max-width: ${DEVICES.mobileS})`,
  xs: `(max-width: ${DEVICES.mobileM})`,
  sm: `(max-width: ${DEVICES.mobileL})`,
  md: `(max-width: ${DEVICES.tablet})`,
  lg: `(max-width: ${DEVICES.laptop})`,
  xl: `(max-width: ${DEVICES.laptopL})`,
  xxl: `(max-width: ${DEVICES.desktop})`,
};

export const PRIMARY_COLOR = 'rgba(239, 44, 58, 1)';
export const TEXT_PRIMARY = '#2d271e';
export const TEXT_SECONDARY = '#909190';
