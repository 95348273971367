const strings = {
  en: {
    NAVBAR_HOME: 'Home',
    NAVBAR_CONTACT: 'Contact',
    CITY_PAGE_TITLE: 'Best things to do in {cityName}',
    CITY_PAGE_TITLEMONUMENT: 'Most famous monuments to see in {cityName}',
    CITY_PAGE_TITLENIGHT_CLUB: 'The best night clubs in the city of {cityName}',
    CITY_PAGE_TITLEVENUE: 'Venues to visit in the city of {cityName}',
    CITY_PAGE_TITLEMUSEUM:
      'The best museums to visit in the city of {cityName}',
    CITY_META_DESCRIPTION:
      'Discover the best places in {cityName}. Museums, Night Clubs, Bars, Parks, Monuments. The {cityName} region has a lot to offer to visitors and locals.',
    CITY_META_DESCRIPTIONMONUMENT:
      'Discover most famous monuments of {cityName}.',
    CITY_META_DESCRIPTIONNIGHT_CLUB:
      'Discover most famous night clubs of {cityName}. Party in {cityName} !',
    CITY_META_DESCRIPTIONVENUE:
      'The city of {cityName} is full of neighborhoods as magnificient as they are unusual. If you are passing through, you have to visit these places!',
    CITY_META_DESCRIPTIONMUSEUM: 'Best museums to visit',
    CITIES_OF_SWITZERLAND: 'Biggest Swiss cities',
    CITY_ARTICLE_IMAGE: 'Image of the city of {cityName}',
    FOOTER_TITLE: 'Discover more on the mobile app',
    LATEST_BLOG_POSTS: 'Latest blog posts',
    BOOK_ACTIVITIES: 'Book activities in {cityName}',
    HOMEPAGE_SUBTITLE: 'Your digital travel companion',
    HOMEPAGE_HERO_BLOB_TITLE: 'Find the next thing for you.',
    HOMEPAGE_HERO_BLOB_DESCRIPTION:
      'Use your smartphone to find the best places to visit around you',
    HOMEPAGE_HERO_STEPS_TITLE:
      'Craving to explore Switzerland, but donʼt know where to start?',
    HOMEPAGE_HERO_STEPS_DOWNLOAD_TITLE: 'Download Deevent',
    HOMEPAGE_HERO_STEPS_DOWNLOAD_DESC:
      'For free on the iOS and Google Play stores',
    HOMEPAGE_HERO_STEPS_INSPIRED_TITLE: 'Get inspired',
    HOMEPAGE_HERO_STEPS_INSPIRED_DESC:
      'For your next destination with beautiful pictures and informative descriptions',
    HOMEPAGE_HERO_STEPS_EXPLORE_TITLE: 'Explore',
    HOMEPAGE_HERO_STEPS_EXPLORE_DESC:
      'The region or city you are visiting by using our in-app guides',
    HOMEPAGE_HERO_PHONE_TITLE: 'Get personalized tour suggestions',
    HOMEPAGE_HERO_PHONE_DESC:
      'For each city youʼll find great guides on different places to visit. Just pull out your phone and start walking. Youʼll get valuable information on each spot you visit and the ability to book your tickets directly within the app.',
    HOMEPAGE_HERO_PHONE_BUTTON: 'Download the app',
    HOMEPAGE_HERO_PHONE_QUOTE:
      '« This feature will greatly help tourists to maximize the time they have at their disposal to visit a city »',
    HOMEPAGE_HERO_PHONE_QUOTE_AUTHOR: 'Nicolas Meienberger, Co-Founder',
    HOMEPAGE_CONTACT_TITLE: 'Contact us',
    HOMEPAGE_CONTACT_DESC: 'info@deevent.app',
  },
  fr: {
    NAVBAR_HOME: 'Accueil',
    NAVBAR_CONTACT: 'Contact',
    CITY_PAGE_TITLE: 'Les meilleurs endroits à visiter à {cityName}',
    CITY_PAGE_TITLEMONUMENT: 'Les plus beaux monuments à voir à {cityName}',
    CITY_PAGE_TITLENIGHT_CLUB: 'Les meilleures boîtes de nuit de {cityName}',
    CITY_PAGE_TITLEVENUE:
      'Tous les quartiers à visiter dans la ville de {cityName}',
    CITY_PAGE_TITLEMUSEUM:
      'Les meilleurs musées à visiter dans la ville de {cityName}',
    CITY_META_DESCRIPTION:
      'Découvrez les meilleurs endroits de {cityName}. Musées, boîtes de nuits, bars, parcs, monuments. La région de {cityName} a beaucoup à offrir aux visiteurs comme aux locaux',
    CITY_META_DESCRIPTIONMONUMENT:
      'Les plus beaux monuments de la ville de {cityName}.',
    CITY_META_DESCRIPTIONNIGHT_CLUB:
      'Découvrez les plus grandes boîtes de nuits et clubs de la ville de {cityName}. Faire la fête à {cityName} !',
    CITY_META_DESCRIPTIONVENUE:
      "La ville de {cityName} regorge de quartiers aussi magnifiques qu'insolites. Si vous êtes de passage, vous devez visiter ces endroits",
    CITY_META_DESCRIPTIONMUSEUM: 'Les meilleurs musées à visiter',
    CITIES_OF_SWITZERLAND: 'Grandes villes Suisses',
    CITY_ARTICLE_IMAGE: 'Image de la ville de {cityName}',
    FOOTER_TITLE: "Découvrez-en plus sur l'application mobile",
    LATEST_BLOG_POSTS: 'Les derniers articles',
    BOOK_ACTIVITIES: 'Réservez des activités à {cityName}',
    HOMEPAGE_SUBTITLE: 'Votre compagnon de voyage digital',
    HOMEPAGE_HERO_BLOB_TITLE: 'Trouvez votre prochaine destination.',
    HOMEPAGE_HERO_BLOB_DESCRIPTION:
      'Utilisez votre smartphone pour découvrir les plus beaux endroits à visiter autour de vous.',
    HOMEPAGE_HERO_STEPS_TITLE:
      "Envie d'explorer la Suisse mais vous ne savez pas par où commencer ?",
    HOMEPAGE_HERO_STEPS_DOWNLOAD_TITLE: 'Téléchargez Deevent',
    HOMEPAGE_HERO_STEPS_DOWNLOAD_DESC:
      'Gratuitement dans les stores iOS et Google Play',
    HOMEPAGE_HERO_STEPS_INSPIRED_TITLE: 'Laissez-vous inspirer',
    HOMEPAGE_HERO_STEPS_INSPIRED_DESC:
      'Pour votre prochaine aventure grâce à de magnifiques images et des informations précises.',
    HOMEPAGE_HERO_STEPS_EXPLORE_TITLE: 'Explorez',
    HOMEPAGE_HERO_STEPS_EXPLORE_DESC:
      'La région ou ville que vous visitez grâce à nos guides intégrés',
    HOMEPAGE_HERO_PHONE_TITLE: 'Suggestions de programmes personnalisés',
    HOMEPAGE_HERO_PHONE_DESC:
      "Pour chaque ville disponible sur Deevent, vous trouverez des guides thématiques vous emmenant vers différents lieux. Sortez votre téléphone et commencez à marcher ! Vous obtiendrez des informations qualitatives pour chaque lieu que vous visiterez et vous pourrez réserver vos places directement depuis l'application",
    HOMEPAGE_HERO_PHONE_BUTTON: "Téléchargez l'application",
    HOMEPAGE_HERO_PHONE_QUOTE:
      "« Cette fonctionnalité va permettre aux touristes de maximiser le temps qu'ils ont à disposition lors d'une visite »",
    HOMEPAGE_HERO_PHONE_QUOTE_AUTHOR: 'Nicolas Meienberger, Co-Fondateur',
    HOMEPAGE_CONTACT_TITLE: 'Contactez-nous',
    HOMEPAGE_CONTACT_DESC: 'info@deevent.app',
  },
  de: {
    NAVBAR_HOME: 'Home',
    NAVBAR_CONTACT: 'Kontakt',
    CITY_PAGE_TITLE: 'Das Beste, was man in {cityName} tun kann',
    CITY_PAGE_TITLEMONUMENT:
      'Die berühmtesten Denkmäler, die man in {cityName} sehen kann',
    CITY_PAGE_TITLENIGHT_CLUB: 'Die besten Nachtclubs in der Stadt {cityName}',
    CITY_PAGE_TITLEVENUE: 'Sehenswerte Orte in der Stadt {cityName}',
    CITY_PAGE_TITLEMUSEUM:
      'Die besten Museen, die man in der Stadt {cityName} besuchen kann',
    CITY_META_DESCRIPTION:
      'Entdecken Sie die besten Orte in {StadtName}. Museen, Nachtclubs, Bars, Parks, Denkmäler. Die Region {cityName} hat für Besucher und Einheimische viel zu bieten.',
    CITY_META_DESCRIPTIONMONUMENT:
      'Entdecken Sie die berühmtesten Denkmäler in {cityName}.',
    CITY_META_DESCRIPTIONNIGHT_CLUB:
      'Entdecken Sie die berühmtesten Nachtclubs in {cityName}. Party in {cityName} !',
    CITY_META_DESCRIPTIONVENUE:
      'Die Stadt {cityName} ist voller grossartigen und aussergewöhnlichen Viertel. Wenn Sie auf der Durchreise sind, müssen Sie diese Orte besuchen!',
    CITY_META_DESCRIPTIONMUSEUM: 'Die besten Museen für einen Besuch',
    CITIES_OF_SWITZERLAND: 'Grösste Schweizer Städte',
    CITY_ARTICLE_IMAGE: 'Bild der Stadt {cityName}',
    FOOTER_TITLE: 'Entdecken Sie mehr in der mobilen App',
    LATEST_BLOG_POSTS: 'Neueste Blog-Beiträge',
    BOOK_ACTIVITIES: 'Aktivitäten in {cityName} buchen',
    HOMEPAGE_SUBTITLE: 'Dein digitaler Reisebegleiter',
    HOMEPAGE_HERO_BLOB_TITLE: 'Finde dein nächstes Abenteuer.',
    HOMEPAGE_HERO_BLOB_DESCRIPTION:
      'Verwende dein Smartphone, um die besten Orte in deiner Nähe zu finden.',
    HOMEPAGE_HERO_STEPS_TITLE:
      'Hast du Lust die Schweiz zu erkunden, weisst aber nicht wo anfangen?',
    HOMEPAGE_HERO_STEPS_DOWNLOAD_TITLE: 'Deevent herunterladen',
    HOMEPAGE_HERO_STEPS_DOWNLOAD_DESC:
      'Kostenfrei im Apple Store und Google Play Store.',
    HOMEPAGE_HERO_STEPS_INSPIRED_TITLE: 'Lass dich inspirieren',
    HOMEPAGE_HERO_STEPS_INSPIRED_DESC:
      'Für dein nächstes Reiseziel mit schönen Bildern und informativen Beschreibungen',
    HOMEPAGE_HERO_STEPS_EXPLORE_TITLE: 'Entdecke',
    HOMEPAGE_HERO_STEPS_EXPLORE_DESC:
      'Die Region oder Stadt, die du mit Hilfe unserer In-App-Guide besuchen möchtest.',
    HOMEPAGE_HERO_PHONE_TITLE: 'Erhalte personalisierte Tourvorschläge',
    HOMEPAGE_HERO_PHONE_DESC:
      'Für jede Stadt findest du tolle Ratgeber zu verschiedenen Orten, die du besuchen kannst. Holst einfach dein Smartphone raus und schon gehts los. Du erhältst wertvolle Informationen zu jedem Spot, den du besuchst, sowie die Möglichkeit deine Tickets direkt in der App zu buchen.',
    HOMEPAGE_HERO_PHONE_BUTTON: 'Die App herunterladen',
    HOMEPAGE_HERO_PHONE_QUOTE:
      '« Diese Funktion hilft Touristen, ihre verfügbare Zeit während des Staatsbesuchs zu maximieren »',
    HOMEPAGE_HERO_PHONE_QUOTE_AUTHOR: 'Nicolas Meienberger, Co-Fondateur',
    HOMEPAGE_CONTACT_TITLE: 'Kontaktiere uns',
    HOMEPAGE_CONTACT_DESC: 'info@deevent.app',
  },
};

export default strings;
