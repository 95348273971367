import strings from '../translations/strings';
import { defaultLocale } from '../translations/config';
import { LocalesUnion, TranslationKey } from '../types/interfaces';

export default function useTranslation(locale: LocalesUnion) {
  function t(key: TranslationKey, params = {}) {
    if (!strings?.[locale]?.[key]) {
      console.warn(`Translation '${key}' for locale '${locale}' not found.`);
    }

    const values = Object.entries<string | number>(params);

    let stringValue = strings[locale][key] || strings[defaultLocale][key] || '';

    values.forEach(([k, v]) => {
      stringValue = stringValue.replace(
        new RegExp(`{${k}}`, 'g'),
        v.toString()
      );
    });

    return stringValue;
  }

  return {
    t,
    locale,
  };
}
