import React from 'react';
import { ReactSVG } from 'react-svg';
import './icon.scss';

interface Props {
  light: boolean;
  path: string;
}

const Logo: React.FC<Props> = ({ light, path }) => {
  return (
    <ReactSVG
      beforeInjection={(svg) => {
        if (!light) {
          svg.classList.add('icon-svg-scrolled');
        } else {
          svg.classList.add('icon-svg');
        }
      }}
      wrapper="span"
      src={path}
      className="icon"
    />
  );
};

export default Logo;
