/* eslint-disable global-require */
import React from 'react';
import './logo.scss';

interface Props {
  light: boolean;
}

const Logo: React.FC<Props> = ({ light }) => {
  return (
    <svg
      width="300"
      height="300"
      viewBox="0 20 363 300"
      xmlns="http://www.w3.org/2000/svg"
      className={`logo ${light ? 'logo-svg' : 'logo-svg-scrolled'}`}
    >
      <g>
        <metadata>Logo deevent.app</metadata>
        <g id="svg_16">
          <g
            id="svg_11"
            transform="matrix(0.08187472119358546,0,0,-0.08187472119358546,-2937.429229792015,-2523.2110146093933) "
          >
            <path
              stroke="null"
              id="svg_12"
              d="m37368.605417,-30897.701542c-22,-18 -31,-35 -31,-55c0,-20 132,-249 450,-779c336,-560 454,-765 465,-808c19,-70 19,-142 1,-209c-10,-36 -156,-289 -470,-812c-251,-418 -456,-767 -456,-775c0,-9 13,-30 29,-48l29,-33l384,0l383,0l57,28c31,15 68,43 83,63c14,19 232,378 484,798c514,857 501,829 468,954c-23,88 -905,1565 -973,1629c-76,71 -89,73 -505,73l-367,0l-31,-26z"
            />
            <path
              stroke="null"
              id="svg_13"
              d="m36614.605417,-31043.701542c-35,-32 -385,-622 -398,-671c-12,-47 -5,-95 22,-149c11,-21 92,-158 181,-305c212,-352 212,-354 212,-473c0,-120 -1,-122 -219,-485c-92,-153 -176,-297 -186,-320c-25,-55 -23,-110 5,-171c27,-59 350,-600 374,-626c20,-24 84,-24 104,0c31,34 876,1451 894,1499c25,63 23,155 -4,216c-33,75 -873,1470 -896,1489c-29,24 -60,22 -89,-4z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
