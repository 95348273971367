import { SITE_BASE_URL, SITE_NAME } from './constants';

interface Props {
  title: string;
  path: string;
  creationDate?: string;
  description?: string;
  image?: string;
  author?: string;
  locale: string;
}

export const createLdJsonSchema = (props: Props) => {
  const {
    title,
    path,
    creationDate,
    description,
    image,
    author,
    locale,
  } = props;
  const published = new Date(creationDate || '');

  const org = {
    '@id': `${SITE_BASE_URL}#organization`,
    type: 'Organization',
    sameAs: ['https://twitter.com/deeventapp'],
    name: `${SITE_NAME}`,
    logo: {
      '@type': 'ImageObject',
      name: `${SITE_NAME} Logo`,
      width: '230',
      height: '67',
      url: `${SITE_BASE_URL}logo.svg`,
    },
  };

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://deevent.app/${path}`,
    },
    headline: title,
    description,
    image,
    author: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Deevent',
      logo: {
        '@type': 'ImageObject',
        url: 'https://deevent.app/logo.svg',
      },
    },
    datePublished: published,
    dateModified: published,
    sourceOrganization: org,
    url: `https://deevent.app${path}`,
    inLanguage: locale,
  };

  return JSON.stringify(schema);
};
